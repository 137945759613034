export default {
    // content 1
    'about.content1.1.title': 'Habby',
    'about.content1.1.description': `Habby是Hobby和Happy的结合。Habby的愿景是希望通过游戏帮助人们建立兴趣，给我们的生活带来意义。快乐与发展是公司发展的灵魂。`,
    'about.content1.2.title': '关于抄袭',
    'about.content1.2.description': `我们反对抄袭，支持原创！一个花了很多时间和精力创造的想法被抄袭，会让创作者感受到痛苦和遗憾。所以，我们拒绝抄袭，并坚持用原创的游戏作品给全球玩家带来快乐！`,
    // content 2
    'about.content2.1.title': '海彼成立',
    'about.content2.1.date': '2018.01',
    'about.content2.1.desc': `CEO王嗣恩于2018年1月在新加坡创立了海彼游戏`,
    'about.content2.2.title': '第一款游戏',
    'about.content2.2.date': '2018.08',
    'about.content2.2.desc': `海彼推出了第一款游戏《萨满爱消除》，这是一款由土耳其研发商Mavis Games于2016年研发的作品`,
    'about.content2.3.title': '第二款游戏-《赤核》',
    'about.content2.3.date': '2018.10',
    'about.content2.3.desc': `这是一款由4名中国大学生研发的独立游戏，在全球获得了广泛的好评。`,
    'about.content2.4.title': '第三款游戏-《弓箭传说》',
    'about.content2.4.date': '2019.05',
    'about.content2.4.desc': `由 Gorilla Games 研发的一款火爆全球的游戏，在全球多个国家的游戏商店占据排行榜榜首的位置。`,
    'about.content2.5.title': '第四款游戏-《企鹅之岛》',
    'about.content2.5.date': '2019.11',
    'about.content2.5.desc': `由韩国独立工作室 Fantome 研发，可爱的小企鹅治愈你的一切。`,
    // 'about.content2.6.title': '第五款游戏-《超级棒球》',
    // 'about.content2.6.date': '2020.10',
    // 'about.content2.6.desc': `由加拿大工作室 Hothead Games 研发，让棒球游戏更加的轻松休闲。`,
    'about.content2.6.title': '第五款游戏-《旋转节奏》',
    'about.content2.6.date': '2021.05',
    'about.content2.6.desc': `澳大利亚独立工作室 Super Spin Entertainment 开发的一款热门音乐节奏游戏，在 steam 平台好评如潮,2021年5月于移动端上线。`,
    'about.content2.7.title': '第六款游戏 - 《酷喵跑酷》',
    'about.content2.7.date': '2022.07',
    'about.content2.7.desc': `由中国团队火瞳游戏和Gorilla Games合作开发的一款动作类跑酷游戏！`,
    'about.content2.8.title': '第七款游戏 - 《弹壳特攻队》',
    'about.content2.8.date': '2022.08',
    'about.content2.8.desc': `Gorilla Games开发的火爆全球的一款生存割草类游戏。`,
    'about.content2.9.title': '第八款游戏 - 《蛇蛇贪吃》',
    'about.content2.9.date': '2023.03',
    'about.content2.9.desc': `蛇蛇贪吃，由壹多游戏研发，一款融合经典贪食蛇与Roguelite的弹幕射击手游！`,
    'about.content2.10.title': '第九款游戏 - 《光之境》',
    'about.content2.10.date': '2023.08',
    'about.content2.10.desc': `《光之境》，由Concrit Inc.精心打造的角色扮演冒险之旅手游。`,
    // content 3
    // content 3
    'about.content3.title': '加入我们',
    'about.content3.description': `打造精英团队，制作可以影响一代人的原创精品游戏！`,
    'about.content3.buttonText': '查看职位'
};
