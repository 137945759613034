import React, { useEffect } from 'react';
import './down-header.less';
import { DownContainer } from '@/components';

const DownHeaderView = () => {

    return (
            <div className='headerDown'>
                <DownContainer
                    className="image"
                    imageRender={() => {
                        return (
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/down/phoneTitle.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/down/Title.png')}
                                    alt=""
                                />
                            </picture>
                        );
                    }}
                ></DownContainer>
            </div>
    );
};

export default DownHeaderView;
