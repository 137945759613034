export default {
    id: 'souls',
    downloadUrl: {
        ios:
            'https://apps.apple.com/app/souls/id6448900613',
        android:
            'https://play.google.com/store/apps/details?id=com.habby.souls',
        apk:
            'https://applink.soulssvc.com/apk/souls.apk'
    },
    media: {
        Facebook: 'https://www.facebook.com/SOULS.official',
        Twitter: 'https://twitter.com/Souls_US_',
        Ins: 'https://www.instagram.com/souls_official_habby/'
    },
    main1: {
        'default': {
            'en-US': require('./img/main1_en.png'),
            'zh-CN': require('./img/main1_zh.png')
        },
        '768': {
            'en-US': require('./img/main1_en_768-min.png'),
            'zh-CN': require('./img/main1_zh_768-min.png')
        },
    },
    main2: {
        'default': {
            'en-US': require('./img/main2_en.png'),
            'zh-CN': require('./img/main2_zh.png')
        },
        '768': {
            'en-US': require('./img/main2_en_768-min.png'),
            'zh-CN': require('./img/main2_zh_768-min.png')
        },
    },
    main3: {
        'default': {
            'en-US': require('./img/main3_en.png'),
            'zh-CN': require('./img/main3_zh.png')
        },
        '768': {
            'en-US': require('./img/main3_en_768-min.png'),
            'zh-CN': require('./img/main3_zh_768-min.png')
        },
    },
    main4: {
        'default': {
            'en-US': require('./img/main4_en.png'),
            'zh-CN': require('./img/main4_zh.png')
        },
        '768': {
            'en-US': require('./img/main4_en_768-min.png'),
            'zh-CN': require('./img/main4_zh_768-min.png')
        },
    },
    main5: {
        'default': {
            'en-US': require('./img/main5_en.png'),
            'zh-CN': require('./img/main5_zh.png')
        },
        '768': {
            'en-US': require('./img/main5_en_768-min.png'),
            'zh-CN': require('./img/main5_zh_768-min.png')
        },
    },

}