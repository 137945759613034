import React from 'react';
import { SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import LocationBeijing from './img/location_beijing@2x.png';
import LocationXJP from './img/location_singapore@2x.png';

import './career-content-3.view.less';

const CareerContent3View = () => {
    return (
        <SectionContainer
            className="career-content-3-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/career/career_content_3@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/career/career_content_3.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h1 className="title">
                    {formatMessage('career.content3.title')}
                </h1>
                <p className="desc">
                    {formatMessage('career.content3.description')}
                </p>
                <div className="location-list">
                    <div className="location-item">
                        <img src={LocationBeijing} alt="" />
                        <h4 className="location-title">
                            {formatMessage('career.content3.location.1.title')}
                        </h4>
                        <p className="location-desc">
                            {formatMessage('career.content3.location.1.desc')}
                        </p>
                    </div>
                    <div className="location-item">
                        <img src={LocationXJP} alt="" />
                        <h4 className="location-title">
                            {formatMessage('career.content3.location.2.title')}
                        </h4>
                        <p className="location-desc">
                            {formatMessage('career.content3.location.2.desc')}
                        </p>
                    </div>
                </div>
            </div>
        </SectionContainer>
    );
};

export default CareerContent3View;
