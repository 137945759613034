export default {
    // banner
    'contact.banner.title': 'Submit Your Game',
    'contact.banner.description': `Habby is looking for exceptional producers and creative teams to help bring their innovative and fun games to the market.`,
    // content 1
    'contact.content1.title': 'What We’re Looking For:',
    'contact.content1.desc': `We’re currently looking for games that are original and fun. Our goal is to ensure games last long in the market and so we’re looking for games which have a long-life span. If you’ve got an idea or a demo, even a complete game, please reach out below!`,
    // content 2
    'contact.content2.form.title': 'Game Details',
    'contact.content2.form.field1.label': 'Name',
    'contact.content2.form.field2.label': 'Description',
    'contact.content2.form.field3.label': 'Primary Genre',
    'contact.content2.form.field4.label': 'Development Stage',
    'contact.content2.form.field5.label':
        'Links  APK, Google Play, iOS, TestFlight',
    'contact.content2.form.field6.label': 'Studio/Company Name',
    'contact.content2.form.field7.label': 'Team Size',
    'contact.content2.form.field8.label': 'Location',
    'contact.content2.form.field9.label': 'Email',
    'contact.content2.form.buttonText': 'Submit',
    'contact.content2.form.buttonTextSuccess': 'Submitted!',
    'contact.content2.form.buttonTextError': 'Submit Failure!',
    'contact.content2.form.buttonTextLoading': 'Submitting...'
};
