import React from 'react';
import { formatMessage } from '@/locales';
import GameCard from '../components/game-card/game-card.component';
import './game-content-1.view.less';

const GameContent1View = () => {
    return (
        <div className="game-content-1-view">
            {/* Survivor */}
            <GameCard
                title={formatMessage('game.content1.game8.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/Survivor@768.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/Survivor.png')}
                                    alt=""
                                />
                                
                            </picture>
                            <source
                                srcSet={require('@/views/game-detail/img/game-survivor/icon.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="icon"
                                src={require('@/views/game-detail/img/game-survivor/icon.png')}
                                alt=""
                            />
                        </div>
                    );
                }}
                desc={formatMessage('game.content1.game8.desc')}
                id="survivor"
                buttonText={formatMessage('game.content1.game8.buttonText')}
            />

            {/* Archerro */}
            <GameCard
                title={formatMessage('game.content1.game1.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/game_cover_archero@768.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/game_cover_archero.png')}
                                    alt=""
                                />
                                
                            </picture>
                            <source
                                srcSet={require('@/views/game-detail/img/game-archero/archero-icon.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="icon"
                                src={require('@/views/game-detail/img/game-archero/archero-icon@2x.png')}
                                alt=""
                            />
                        </div>
                    );
                }}
                desc={formatMessage('game.content1.game1.desc')}
                id="archero"
                buttonText={formatMessage('game.content1.game1.buttonText')}
            />

             {/* Souls */}
            <GameCard
                title={formatMessage('game.content1.game11.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/souls.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/souls.png')}
                                    alt=""
                                />
                                
                            </picture>
                            <source
                                srcSet={require('@/views/game-detail/img/game-souls/icon.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="icon"
                                src={require('@/views/game-detail/img/game-souls/icon.png')}
                                alt=""
                            />
                        </div>
                    );
                }}
                desc={formatMessage('game.content1.game11.desc')}
                id="souls"
                buttonText={formatMessage('game.content1.game11.buttonText')}
            />

            {/* SSSnaker */}
            <GameCard
                title={formatMessage('game.content1.game10.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/snake@768.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/snake.png')}
                                    alt=""
                                />
                                
                            </picture>
                            <source
                                srcSet={require('@/views/game-detail/img/game-snake/icon.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="icon"
                                src={require('@/views/game-detail/img/game-snake/icon.png')}
                                alt=""
                            />
                        </div>
                    );
                }}
                desc={formatMessage('game.content1.game10.desc')}
                id="snake"
                buttonText={formatMessage('game.content1.game10.buttonText')}
            />

            {/* punball */}
            <GameCard
                title={formatMessage('game.content1.game3.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/game_cover_punball@768.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/game_cover_punball.png')}
                                    alt=""
                                />
                            </picture>
                            <source
                                    srcSet={require('@/views/game-detail/img/game-punball/icon.png')}
                                    media="(max-width: 768px)"
                                />
                            <img
                                className="icon"
                                src={require('@/views/game-detail/img/game-punball/icon@2x.png')}
                                alt=""
                            />
                        </div>
                    );
                }}
                desc={formatMessage('game.content1.game3.desc')}
                id="punball"
                buttonText={formatMessage('game.content1.game3.buttonText')}
            />

            {/* Penguin Isle */}
            <GameCard
                title={formatMessage('game.content1.game2.title')}
                imageRender={() => {
                    return (
                        <div>
                        <picture>
                            <source
                                srcSet={require('@/assets/img/game/game_cover_penguin@768.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="background-image"
                                src={require('@/assets/img/game/game_cover_penguin.png')}
                                alt=""
                            />
                            </picture>
                            <source
                                srcSet={require('@/views/game-detail/img/game-penguin/icon.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="icon"
                                src={require('@/views/game-detail/img/game-penguin/icon@2x.png')}
                                alt=""
                            />
                        </div>
                    );
                }}
                desc={formatMessage('game.content1.game2.desc')}
                id="penguin"
                buttonText={formatMessage('game.content1.game2.buttonText')}
            />

            {/* Flaming Core */}
            <GameCard
                title={formatMessage('game.content1.game7.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/game_cover_flamingcore@768.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/game_cover_flamingcore.png')}
                                    alt=""
                                />
                                
                            </picture>
                            <source
                                srcSet={require('@/views/game-detail/img/game-core/icon.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="icon"
                                src={require('@/views/game-detail/img/game-core/icon@2x.png')}
                                alt=""
                            />
                        </div>
                    );
                }}
                desc={formatMessage('game.content1.game7.desc')}
                id="flamingCore"
                buttonText={formatMessage('game.content1.game7.buttonText')}
            />
            
            {/* Slidey: Block Puzzle */}
            <GameCard
                title={formatMessage('game.content1.game4.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/game_cover_slidey@768.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/game_cover_slidey.png')}
                                    alt=""
                                />
                            </picture>
                            <source
                                srcSet={require('@/views/game-detail/img/game-slidey/icon.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="icon"
                                src={require('@/views/game-detail/img/game-slidey/icon@2x.png')}
                                alt=""
                            />
                        </div>
                    );
                }}
                desc={formatMessage('game.content1.game4.desc')}
                id="slidey"
                buttonText={formatMessage('game.content1.game4.buttonText')}
            />

            
            {/* <GameCard
                title={formatMessage('game.content1.game5.title')}
                imageRender={() => {
                    return (
                        <div>
                        <picture>
                            <source
                                srcSet={require('@/assets/img/game/game_cover_baseball@768.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="background-image"
                                src={require('@/assets/img/game/game_cover_baseball.png')}
                                alt=""
                            />
                        </picture>
                        <source
                                srcSet={require('@/views/game-detail/img/game-baseball/icon.png')}
                                media="(max-width: 768px)"
                            />
                        <img
                            className="icon"
                            src={require('@/views/game-detail/img/game-baseball/icon@2x.png')}
                            alt=""
                        />  
                    </div> 
                    );
                }}
                desc={formatMessage('game.content1.game5.desc')}
                id="5"
                buttonText={formatMessage('game.content1.game5.buttonText')}
            /> */}

            {/* Spin Rhythm */}
            {/* <GameCard
                title={formatMessage('game.content1.game6.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/game_cover_rhythm@768.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/game_cover_rhythm.png')}
                                    alt=""
                                />
                                
                            </picture>
                            <source
                            srcSet={require('@/views/game-detail/img/game-rhythm/icon@2x.png')}
                            media="(max-width: 768px)"
                        />
                            <img
                            className="icon"
                            src={require('@/views/game-detail/img/game-rhythm/icon.png')}
                            alt=""
                        />
                    </div>
                    );
                }}
                desc={formatMessage('game.content1.game6.desc')}
                id="spinRhythm"
                buttonText={formatMessage('game.content1.game6.buttonText')}
            /> */}

            {/* Kinja Run */}
            <GameCard
                title={formatMessage('game.content1.game9.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/KinjaRun@768.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/KinjaRun.png')}
                                    alt=""
                                />
                                
                            </picture>
                            <source
                                srcSet={require('@/views/game-detail/img/game-kinjarun/icon.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                className="icon"
                                src={require('@/views/game-detail/img/game-kinjarun/icon.png')}
                                alt=""
                            />
                        </div>
                    );
                }}
                desc={formatMessage('game.content1.game9.desc')}
                id="kinjaRun"
                buttonText={formatMessage('game.content1.game9.buttonText')}
            />

            {/* Fishdom */}
            <GameCard
                title={formatMessage('game.content1.game13.title')}
                imageRender={() => {
                    return (
                        <div>
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/game/fishdom@768.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/game/fishdom.png')}
                                    alt=""
                                />
                                
                            </picture>
                            <source
                            srcSet={require('@/views/game-detail/img/game-rhythm/icon@2x.png')}
                            media="(max-width: 768px)"
                        />
                            <img
                            className="icon"
                            src={require('@/assets/img/game/fishdom_icon.png')}
                            alt=""
                        />
                    </div>
                    );
                }}
                desc={formatMessage('game.content1.game13.desc')}
                id="fishdom"
                buttonText={formatMessage('game.content1.game13.buttonText')}
            />

            <GameCard
                title={formatMessage('game.content1.game12.title')}
            />

            <GameCard
                title={formatMessage('game.content1.game12.title')}
            />
        </div>
    );
};

export default GameContent1View;
