import React, { useEffect } from 'react';
import { Header, Footer } from '@/components';
import AboutBannerView from './about-banner/about-banner.view';
import AboutContent1View from './about-content-1/about-content-1.view';
import AboutContent2View from './about-content-2/about-content-2.view';
import AboutContent22View from './about-content-2-2/about-content-2-2.view';
import AboutContent3View from './about-content-3/about-content-3.view';

const AboutView = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <div className="default-view">
                <AboutBannerView />
                <AboutContent1View />
                <AboutContent2View />
                <AboutContent22View />
                <AboutContent3View />
                <Footer />
            </div>
        </>
    );
};

export default AboutView;
