/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FieldForm, { Field, useForm } from 'rc-field-form';
import { useDebounceFn } from 'ahooks';
import { formatMessage } from '@/locales';
import classNames from 'classnames';
import TopLeftImage from './img/top-left@2x.png';
import TopRightImage from './img/top-right@2x.png';
import BottomRightImage from './img/bottom-right@2x.png';
import CardBackgroundImage from './img/card-background@2x.png';

import './contact-content-2.view.less';

const Input = props => {
    const { onChange, onInput, value: propsValue, ...otherProps } = props;

    const [scopeValue, setScopedValue] = useState('');

    const triggerOnChange = newValue => {
        if (typeof onChange === 'function') {
            onChange(newValue);
        }
        if (!('value' in props)) {
            setScopedValue(newValue);
        }
    };

    const onChangeHandler = event => {
        triggerOnChange(event.target.value);
    };

    useEffect(() => {
        if ('value' in props && propsValue !== scopeValue) {
            setScopedValue(propsValue);
        }
    }, [props, propsValue, scopeValue]);

    return (
        <TextField
            {...otherProps}
            onChange={onChangeHandler}
            value={scopeValue || ''}
        />
    );
};

const ContactContent2View = () => {
    const [form] = useForm();

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const validateForm = async () => {
        try {
            const validateResult = await form.validateFields();
            return validateResult;
        } catch (errorInfo) {
            return false;
        }
    };

    const onClickHandler = async () => {
        if (submitDisabled) {
            return null;
        }
        const formData = form.getFieldsValue();
        setIsLoading(true);
        try {
            const response = await fetch(
                'https://officalwebsite.habbyservice.com/api/contact',
                {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                }
            );
            const { status } = await response;
            setIsLoading(false);
            if (status === 200) {
                setIsSuccess(true);
                form.resetFields();
                setSubmitDisabled(true);
            }
        } catch (error) {
            setIsError(true);
        }
    };

    const onFormValuesChangeHandler = async () => {
        const formData = await validateForm();
        if (formData) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
        if (isSuccess) {
            setIsSuccess(false);
        }
        if (isError) {
            setIsError(false);
        }
    };

    const debounceOnChange = useDebounceFn(
        (...args) => {
            onFormValuesChangeHandler(...args);
        },
        { wait: 300 }
    );

    const getButtonText = () => {
        if (isLoading === true) {
            return formatMessage('contact.content2.form.buttonTextLoading');
        }
        if (isSuccess === true) {
            return formatMessage('contact.content2.form.buttonTextSuccess');
        }
        if (isError === true) {
            return formatMessage('contact.content2.form.buttonTextError');
        }

        return formatMessage('contact.content2.form.buttonText');
    };

    return (
        <div className="contact-content-2-view">
            <div className="contact-card">
                <div className="background-image">
                    <img src={CardBackgroundImage} alt="" />
                </div>
                <div className="top-left-img">
                    <img src={TopLeftImage} alt="" />
                </div>
                <div className="top-right-img">
                    <img src={TopRightImage} alt="" />
                </div>
                <div className="bottom-right-img">
                    <img src={BottomRightImage} alt="" />
                </div>
                <FieldForm
                    name="form"
                    component={false}
                    form={form}
                    onValuesChange={(...args) => {
                        debounceOnChange.run(...args);
                    }}
                >
                    <div className="content">
                        <h2 className="title">
                            {formatMessage('contact.content2.form.title')}
                        </h2>
                        <Field name="appName" rules={[{ required: true }]}>
                            <Input
                                className="input-item"
                                label={formatMessage(
                                    'contact.content2.form.field1.label'
                                )}
                                required
                            />
                        </Field>
                        <Field name="appDesc" rules={[{ required: true }]}>
                            <Input
                                className="input-item"
                                label={formatMessage(
                                    'contact.content2.form.field2.label'
                                )}
                                required
                            />
                        </Field>
                        <Field name="appType" rules={[{ required: true }]}>
                            <Input
                                className="input-item"
                                label={formatMessage(
                                    'contact.content2.form.field3.label'
                                )}
                                required
                            />
                        </Field>
                        <Field name="developStage" rules={[{ required: true }]}>
                            <Input
                                className="input-item"
                                label={formatMessage(
                                    'contact.content2.form.field4.label'
                                )}
                                required
                            />
                        </Field>
                        <Field name="appLink" rules={[{ required: true }]}>
                            <Input
                                className="input-item"
                                label={formatMessage(
                                    'contact.content2.form.field5.label'
                                )}
                                required
                            />
                        </Field>
                        <Field name="teamName" rules={[{ required: true }]}>
                            <Input
                                className="input-item"
                                label={formatMessage(
                                    'contact.content2.form.field6.label'
                                )}
                                required
                            />
                        </Field>
                        <Field name="teamSize" rules={[{ required: true }]}>
                            <Input
                                className="input-item"
                                label={formatMessage(
                                    'contact.content2.form.field7.label'
                                )}
                                required
                            />
                        </Field>
                        <Field name="address" rules={[{ required: true }]}>
                            <Input
                                className="input-item"
                                label={formatMessage(
                                    'contact.content2.form.field8.label'
                                )}
                                required
                            />
                        </Field>
                        <Field name="contactEmail" rules={[{ required: true }]}>
                            <Input
                                className="input-item"
                                label={formatMessage(
                                    'contact.content2.form.field9.label'
                                )}
                                required
                            />
                        </Field>

                        <button
                            className={classNames('contact-submit-button', {
                                disabled: submitDisabled === true
                            })}
                            type="button"
                            onClick={onClickHandler}
                        >
                            <span>{getButtonText()}</span>
                        </button>
                    </div>
                </FieldForm>
            </div>
        </div>
    );
};

export default ContactContent2View;
