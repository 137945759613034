import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
// import { ConfigProvider } from 'antd';
// import zhCN from 'antd/es/locale/zh_CN';
import { Provider } from '@yuandana/react-redux-x';
import Router from '@/router';
import store from '@/store';
// import '@/assets/js/mobile.handler';
import '@/assets/css/reset.less';
import '@/assets/css/common.less';

function RootView() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        store.dispatch('AUTH_INIT_LGOIN_INFO');
    }, []);

    return (
        // <ConfigProvider locale={zhCN}>
        <Provider store={store}>
            <Router />
        </Provider>
        // </ConfigProvider>
    );
}

ReactDOM.render(<RootView />, document.getElementById('root'));

// 模块热替换的 API
if (module.hot) {
    module.hot.accept();
}
