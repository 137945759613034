import React, { useEffect } from 'react';
import { Header, Footer } from '@/components';
import GameBannerView from './game-banner/game-banner.view';
import GameContent1View from './game-content-1/game-content-1.view';
import GameContent2View from './game-content-2/game-content-2.view';

const GameView = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <div className="default-view">
                <GameBannerView />
                <GameContent1View />
                <GameContent2View />
                <Footer />
            </div>
        </>
    );
};

export default GameView;
