/* eslint-disable react/prop-types */
import React from 'react';
import {
    BrowserRouter as ReactRouter,
    Switch,
    Redirect,
    useLocation
} from 'react-router-dom';
import { connect } from '@yuandana/react-redux-x';
import { navigationRef } from './root-navigate';
import AuthRoute from './auth-route';
import routesData from './routes';

function isReactComponent(obj) {
    if (!obj) {
        return false;
    }
    if (obj.prototype && obj.prototype.isReactComponent) {
        return true;
    }
    if (typeof obj === 'object' && obj.$$typeof) {
        return true;
    }
    if (typeof obj === 'function') {
        return true;
    }
    return false;
}

/**
 * 路由数据处理函数
 * 给路由对象添加需要计算的属性
 * eg: fullPath
 * @param {*} routes
 * @return {*} routes
 */
function routesDataHandler(routes = [], baseUrl = '') {
    return routes.map(route => {
        const { routes: childrenRoutes, path } = route;
        const fullPath = `${baseUrl}${path}`;
        return {
            ...route,
            fullPath,
            routes: routesDataHandler(childrenRoutes, fullPath)
        };
    });
}

/**
 * 提取不带 component 的纯数据路由数据
 * @param {*} routes
 */
// function pureRoutesDataHandler(routes) {
//     return routes.map(route => {
//         const { routes: childrenRoutes, component, ...otherProps } = route;
//         return {
//             ...otherProps,
//             routes: pureRoutesDataHandler(childrenRoutes)
//         };
//     });
// }

/**
 * 渲染 route
 * @param {*} route
 * @param {*} children
 */
function RedirectRoute(props) {
    const { route = {}, children } = props;
    const { redirect, component } = route;
    const location = useLocation();

    //
    if (
        redirect &&
        location.pathname === route.fullPath &&
        location.pathname !== redirect
    ) {
        return (
            <Redirect
                to={{
                    pathname: redirect
                }}
            />
        );
    }

    if (isReactComponent(component)) {
        return <route.component>{children}</route.component>;
    }
    return children;
}

/**
 * 渲染路由
 * @param {*} routes
 */
function renderRoutes(routes = []) {
    if (routes.length === 0) {
        return null;
    }

    const switchChildren = routes.map(route => {
        const resultDoms = [];

        const { routes: childrenRoutes = [], fullPath } = route;

        const children =
            childrenRoutes &&
            Array.isArray(childrenRoutes) &&
            renderRoutes(childrenRoutes);

        const isExact = route.path === '/' || childrenRoutes.length === 0;

        const isStrict = childrenRoutes.length === 0;

        // 预留 <Redirect/> 的 from to 方式
        // if (redirect) {
        //     resultDoms.push(
        //         <Redirect
        //             to={{
        //                 pathname: redirect
        //             }}
        //         />
        //     );
        // }
        resultDoms.push(
            <AuthRoute
                route={route}
                key={fullPath}
                path={fullPath}
                exact={isExact}
                strict={isStrict}
            >
                <RedirectRoute route={route}>{children}</RedirectRoute>
            </AuthRoute>
        );

        return resultDoms;
    });

    return <Switch>{switchChildren.flat()}</Switch>;
}

function Router({ initialized }) {
    // 处理 routes 数据
    const nextRoutes = routesDataHandler(routesData);
    // const pureRoutes = pureRoutesDataHandler(nextRoutes);s

    // 将纯净的 routes 存入 store
    // 供 menu 使用
    // useEffect(() => {
    //     store.dispatch('ROUTER_UPDATE_PURE_ROUTES', pureRoutes);
    // }, [pureRoutes]);

    // 渲染 routes
    if (routesData.length === 0) {
        return null;
    }

    // 判断初始化完成渲染路由相关
    if (initialized) {
        return (
            <ReactRouter ref={navigationRef}>
                {renderRoutes(nextRoutes)}
            </ReactRouter>
        );
    }
    return null;
}

export default connect(state => {
    return {
        initialized: state.app.auth.initialized
    };
})(Router);
