/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';
import './button.less';

export const Button = ({ className, style, children, ...otherProps }) => {
    return (
        <button
            type="button"
            className={classnames('button', className)}
            style={style}
            {...otherProps}
        >
            <span role="img" aria-label="caret-right" className="arrow-right">
                <svg
                    style={{
                        width: '1em',
                        height: '1em',
                        verticalAlign: 'middle',
                        fill: 'currentColor',
                        overflow: 'hidden'
                    }}
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1308"
                >
                    <path
                        d="M210.823529 883.772235V140.227765A120.470588 120.470588 0 0 1 416.466824 55.055059l371.772235 371.772235a120.470588 120.470588 0 0 1 0 170.345412l-371.772235 371.772235A120.470588 120.470588 0 0 1 210.823529 883.772235z"
                        fill="#FFFFFF"
                        p-id="1309"
                        data-spm-anchor-id="a313x.7781069.1998910419.i1"
                    />
                </svg>
            </span>
            <span className="text"> {children || 'Read More'}</span>
        </button>
    );
};
