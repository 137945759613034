import { isObject, isString } from 'lodash-es';
import { LocalStorageService } from '@/services';

import enUS from './en-US';
import zhCN from './zh-CN';

// 当前语音ID
let id = LocalStorageService.get('localId') || 'en-US';

export const locales = {
    'en-US': enUS,
    'zh-CN': zhCN
};

export const setLanguage = localId => {
    if (Object.keys(locales).includes(localId)) {
        id = localId;
    }
};

export const formatMessage = params => {
    const current = locales[id];
    let text;
    let nextMessageId;
    if (isString(params)) {
        nextMessageId = params;
        text = current[nextMessageId];
    }
    if (isObject(params)) {
        const { id: messageId, defaultMessage } = params;
        nextMessageId = messageId;
        text = current[nextMessageId] || defaultMessage;
    }
    // 如果没有对应内容，返回key本身
    if (!text) {
        return nextMessageId;
    }
    return text;
};
