import footer from './footer';
import nav from './nav';
import home from './home';
import career from './career';
import game from './game';
import contact from './contact';
import about from './about';

export default {
    ...nav,
    ...footer,
    ...home,
    ...career,
    ...game,
    ...contact,
    ...about
};
