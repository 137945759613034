export default {
    // banner
    'contact.banner.title': '优质游戏合作',
    'contact.banner.description': `海彼正在寻找杰出的游戏制作人和有创意的游戏团队，我们可以帮助您将有创意且有趣的游戏作品在全球推广发行。`,
    // content 1
    'contact.content1.title': '我们正在寻找：',
    'contact.content1.desc': `海彼正在寻找原创并且有趣的游戏，我们希望能将优秀的游戏作品发行到全球并且对游戏进行长线的运营。如果您有好的创意或游戏demo，甚至是完整的游戏，请联系我们，期待与您的合作共赢！联系方式请见下方。`,
    // content 2
    'contact.content2.form.title': '游戏信息',
    'contact.content2.form.field1.label': '游戏名',
    'contact.content2.form.field2.label': '游戏描述',
    'contact.content2.form.field3.label': '游戏类型',
    'contact.content2.form.field4.label': '开发完成度',
    'contact.content2.form.field5.label': '链接  APK, 商店, TestFlight',
    'contact.content2.form.field6.label': '团队名',
    'contact.content2.form.field7.label': '团队规模',
    'contact.content2.form.field8.label': '所在地址',
    'contact.content2.form.field9.label': '联系邮箱',
    'contact.content2.form.buttonText': '提交 ',
    'contact.content2.form.buttonTextSuccess': '提交成功！',
    'contact.content2.form.buttonTextError': '提交失败',
    'contact.content2.form.buttonTextLoading': '提交中...'
};
