import serverErrorHandlerInterceptor from './server-error-handler.interceptor';
import errorHandlerInterceptor from './error-handler.interceptor';
// import debuggerInterceptor from './debugger.interceptor.js';
import fileHandlerInterceptor from './file-handler.interceptor';

export default [
    // debuggerInterceptor,
    fileHandlerInterceptor,
    serverErrorHandlerInterceptor,
    errorHandlerInterceptor
];
