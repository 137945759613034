import React, { useEffect } from 'react';
import { Header, Footer } from '@/components';
import ContactBannerView from './contact-banner/contact-banner.view';
import ContactContent1View from './contact-content-1/contact-content-1.view';
import ContactContent2View from './contact-content-2/contact-content-2.view';

const ContactView = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <div className="default-view">
                <ContactBannerView />
                <ContactContent1View />
                <ContactContent2View />
                <Footer />
            </div>
        </>
    );
};

export default ContactView;
