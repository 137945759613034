/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';

export const Picture = ({ src }) => {
    const imgStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        objectFit: 'cover'
    };
    return (
        <picture>
            <img style={imgStyle} src={src} alt="" />
        </picture>
    );
};
