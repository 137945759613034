/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import './shadow-card.less';

export const ShadowCard = ({ className, children }) => {
    const classes = classNames('shadow-card', className);
    return (
        <div className={classes}>
            <div className="shadow-card-inner inner">{children}</div>
        </div>
    );
};
