import { nth } from 'lodash-es';

export default {
    // banner
    'career.banner.title': `Make fun make sense`,
    'career.banner.description': 'Join us in this journey!',
    'career.banner.buttonText': `View Jobs`,
    // content 1
    'career.content1.title': 'Want to be a part of it?',
    'career.content1.description': `Our belief is that being simple, honest, self-driven and motivated combined with the constant pursuit of the ultimate company atmosphere can help grow a creative and robust team. `,
    // content 2
    'career.content2.1.title': 'Maria',
    'career.content2.1.subtitle': `Unity 3D Programmer`,
    'career.content2.1.description': `“I love being able to create amazing games with like-minded folks here at Habby!”`,
    'career.content2.2.title': 'Joel',
    'career.content2.2.subtitle': `Game Operator`,
    'career.content2.2.description': `“Habby provides a working atmosphere that’s equal, free and loving! It’s great to find yourself and improve yourself here.”`,
    // content 3
    'career.content3.title': 'Where are we',
    'career.content3.description': `We currently have offices in Singapore and Beijing will further grow to other destinations in the future.`,
    'career.content3.location.1.title': 'Beijing, CHN',
    'career.content3.location.1.desc': '10 Positions',
    'career.content3.location.2.title': 'Singapore, SG',
    'career.content3.location.2.desc': '6 Positions',
    // content 4
    'career.content4.modal.applyButtonText': 'Apply',
    'career.content4.title': 'We are hiring',
    'career.content4.description': `If you wish to apply for any of the above roles, please reach out with your Resume/CV to`,
    'career.content4.description.email': 'zhangxinzhe@habby.com',
    'career.content4.jobs.buttonText': 'Details',
    'career.content4.jobs': [
        {
            title: 'Playable Ads Developer',
            location: 'Beijing',
            type: '运营',
            descriptions: [
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Develop and optimize playable ads using Cocos and Unity3D engines.\n2.Convert Unity-developed projects into playable ads using relevant tools, ensuring the quality and performance of the converted products.\n3.Resolve performance issues, compatibility problems, and other technical challenges during the Unity to H5 conversion process.\n4.Collaborate with the team to continuously optimize the development process, enhance project quality, and efficiency.\n5.Conduct code reviews and performance evaluations to ensure software quality meets company standards.\n6.Continuously research and master the latest industry technologies to apply them to projects for maintaining technical leadership.'
                },
                {
                    title: 'Job Requirements',
                    content:
                        `1.Bachelor's degree or above in Computer Science or related fields.\n2.Proficiency in at least one game development engine: Cocos, Unity.\n3.Experience in developing projects using the Unity engine and familiarity with the technology and tools for converting Unity projects to H5.\n4.Proficient in languages such as C#/JavaScript/TypeScript, with good front-end development skills.\n5.Experience in optimizing H5 application performance and knowledge of best practices in front-end performance optimization.\n6.Strong problem-solving abilities, able to independently face and solve technical challenges.\n7.Strong sense of responsibility and teamwork spirit.`
                },
                {
                    title: 'Additional Requirements',
                    content:
                        '1.Familiar with engine rendering principles, with experience optimizing rendering performance in actual work.\n2.Experience in package compression optimization and successful application in projects.'
                },
                {
                    title: 'Bonus Points',
                    content:
                        'Experience in developing playable ads for large-scale H5 projects.'
                },
            ]
        },
        {
            title: 'Game Programmer',
            location: 'Beijing',
            type: '视频设计',
            descriptions: [
                {
                    title: 'Professional Skills',
                    content:
                        '1.Over 4 years of Unity front-end logic development experience.\n2.Proficient in using UGUI, with a deep understanding of UGUI.\n3.Proficient in developing extension tools using Unity.\n4.Proficient in Lua scripting.'
                },
                {
                    title: 'Comprehensive Qualities ',
                    content:
                        '1.Rich experience in business logic design and development, adept at optimizing and refactoring business-related logical structures.\n2.Strong logical thinking ability, strong learning ability, quality-oriented.\n3.Good communication skills, adept at expressing own viewpoints.\n4.Able to quickly build frameworks and prototype designs.'
                }
            ]
        },
        {
            title: 'Game Quality Assurance Engineer',
            location: 'Beijing',
            type: '测试',
            descriptions: [
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Responsible for mobile game product testing, quickly respond to testing requirements, and ensure the orderly conduct of testing business;\n2.In the testing process, quickly, accurately, and comprehensively identify issues in the game, and make basic judgments on the causes of the issues;\n3.Familiar with project development and testing processes, various testing techniques, testing cases and documents, and writing test reports;\n4.Data-driven, optimize the entire testing process, clarify testing standards, and improve testing quality and efficiency;\n5.Research testing technologies, promote the design and development of testing tools, and enhance the combat effectiveness of the testing team.'
                },
                {
                    title: 'Job Requirements',
                    content:
                        `1.Bachelor's degree or above in computer-related majors, with 5 years or more experience in the testing industry;\n2.Strong logical analysis and learning abilities, strong self-drive, responsible for project quality, and promote continuous improvement of projects;\n3.Good technical skills, proficient in at least one programming language; enthusiastic about technology, actively understanding technological advancements in the testing field;\n4.Passionate about games, able to accept and engage in testing work for various types of games;\n5.Able to accurately describe personal opinions; meticulous, patient, steadfast, with good language expression, collaborative communication skills, logical thinking ability, and strong sense of work responsibility.`
                }
            ]
        },
        {
            title: 'Senior Game Programmer',
            location: 'Beijing',
            type: '研发',
            descriptions: [
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Responsible for the design and development of Android and iOS platform games using the Unity3D game engine;\n2.Responsible for building the game client architecture, developing core engineering modules;\n3.Maintain good communication with game planners, artists, and server developers, assist in establishing an efficient game content production process and standards, continuously improve and optimize toolchains;\n4.Responsible for the quality of game client development, ensuring smooth performance of the game on various devices;\n5.Research cutting-edge game client technologies, establish game technology directions, and find feasible implementation solutions;'
                },
                {
                    title: 'Job Requirements',
                    content:
                        `1.Bachelor's degree or above in computer-related majors; proficient in using Unity3D, familiar with the underlying operation principles of the engine;\n2.Over 5 years of 3D game development experience, responsible for at least one complete research and development and iteration project of a 3D game that has been launched, familiar with the entire process of 3D game development;\n3.Familiar with adaptation and optimization of mobile game development, understanding of U3D and interaction with native languages of mobile systems;\n4.Proficient in using languages such as C#, Lua for core game function development, priority given to those with knowledge and experience in rendering and performance optimization;\n5.Strong coding ability, passionate about the game industry, good at communication, with a strong sense of responsibility and ability to handle pressure.`
                }
            ]
        },
        {
            title: 'Game Level Designer',
            location: 'Beijing',
            type: '数值策划',
            descriptions: [
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Responsible for level content creation, including gameplay design and planning, controlling level difficulty and pacing, etc.;\n2.Have rich ideas on artistic expression, able to propose interesting and reasonable design solutions;\n3.Design gameplay systems around the game framework and level content;\n4.Keep up with popular casual gameplay in the European and American markets, understand their design methods and thought processes, such as match-3, tripeaks, word, hidden objects, etc.;'
                },
                {
                    title: '职位要求',
                    content:
                        '1.Enjoy casual games;\n2.Able to keenly perceive positive and negative experiences in games, and infer corresponding design reasons from them;\n3.Over five years of experience in designing levels for casual games, with priority given to those with experience in system design.'
                }
            ]
        },
        {
            title: 'Game Artist',
            location: 'Beijing',
            type: '运营',
            descriptions: [
                {
                    title: 'Job Requirements',
                    content:
                        '1.Enthusiastic, self-driven, high aesthetic sense, and fun\n2.Good aesthetic sense, strong creativity, able to create designs that captivate players\n3.Excellent graphic design skills and a certain level of illustration (to assist creativity), capable of designing high-quality promotional materials using minimal game resources\n4.Proficient in the European and American cartoon style\n5.Strong sense of purpose, with a clear direction of pursuit, and high quality standards for oneself\n6.Proactive, able to achieve good results in an open and free working environment\n7.Open-minded and receptive to new methods, tools, and optimization of existing work processes\n8.Clear thinking expression, simple, efficient communication, and logical clarity\n9.Familiar with and proficient in common AI drawing tools, such as SD, MJ, etc.\n10.Passionate about games, preferably passionate about Habby games'
                },
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Work closely with the operations team to discuss and promote creativity, responsible for designing materials for online game operation activities\n2.Use existing artistic resources from projects, combine personal artistic abilities to express creativity, and create illustrations or graphic designs for different scenario requirements\n3.Summarize and accumulate experience based on results, optimize design and efficiency\n4.Communicate and collaborate with outsourcing teams, able to independently control the quality and progress of outsourced designs\n5.Quickly learn about new tools and methods, improve efficiency and quality, and apply them to practical projects'
                }
            ]
        },
        {
            title: 'Senior Game UI Artist',
            location: 'Beijing',
            type: 'Senior Game UI Artist',
            descriptions: [
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Responsible for the overall visual design packaging of the in-game UI interface;\n2.Establish the UI style for projects;'
                },
                {
                    title: 'Job Requirements',
                    content:
                        `1.Good foundation in graphic design, mature judgment on popular design aesthetics in the market;\n2.Excellent teamwork skills, able to smoothly connect and collaborate with planning, interaction, animation, restoration, and programming processes;\n3.Excellent drawing skills, outstanding graphic design, color composition, and refinement abilities; deep understanding of game interactions;\n4.Strong ability to summarize and analyze designs, able to accumulate certain methodologies in work, and drive the team's design capabilities forward;\n5.Excellent design control ability, rich experience in adhering to basic design standards and managing resource output.`
                }
            ]
        },
        {
            title: 'Game 3D Artist',
            location: 'Beijing',
            type: '主美',
            descriptions: [
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Independently complete the design and adjustment of the overall atmosphere of the game according to requirements using the u3d engine;\n2.Responsible for creating game models and textures that meet market expectations;\n3.Maintain close collaboration and communication with planning, design, and technical departments;\n4.Understanding of animation, special effects, and other aspects is preferred.'
                },
                {
                    title: 'Job Requirements',
                    content:
                        `1.Over 5 years of experience in 3D game production;\n2.Familiar with the PBR production process and related software (PS, 3DMAX/MAYA, Zb, SP);\n3.Proficient in Unity's material, lighting, post-processing modules;\n4.Able to independently use map editors to create models and terrain;\n5.Strong ability in environmental and spatial layout (good at representing terrain, buildings, vegetation);\n6.Excellent communication and teamwork skills;\n7.Strong self-motivation, with the ability to self-learn and a strong desire to learn;\n8.Passionate about game development, proactive in tasks.`
                }
            ]
        },
        {
            title: 'Game Artist',
            location: 'Beijing',
            type: '游戏原画',
            descriptions: [
                {
                    title: 'Keywords',
                    content:
                        'Western Cartoon, Characters + Scenes, Good Aesthetics, Good Communication, Creative Thinking, Trendy, Understanding of Foreign Trends'
                },
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Proficient in Western cartoon style, matching the style of Western cartoon projects\n2.Strong goal orientation, with a clear direction and quality standards for their work\n3.Independently communicate with project members to break down requirements, determine solutions, and deliver results on time\n4.Proactively drive progress, work in an open and free environment, and take responsibility for results\n5.Task management and time planning skills, able to handle multiple tasks with different priorities simultaneously\n6.Open-mindedness and willingness to accept new methods and optimize existing work modes\n7.Clear expression of thoughts and logical communication skills\n8.Experience in large companies and successful projects is preferred, with a strong passion for games and a personal understanding of games; enthusiasm for Habby games is a plus'
                },
                {
                    title: 'Job Requirements',
                    content:
                        '1.Over 3 years of game development experience, working closely with project teams, independently communicating to break down requirements, determining solutions, and completing character and scene concept designs within the timeline, driving the design progress of the project\n2.Work closely with other art functions, making adjustments to seek the best presentation results\n3.Simple and smooth communication, able to discuss issues efficiently and clearly, open-minded, and able to accept different opinions\n4.Knowledge and experience in art design methodologies, capable of creating interesting and attractive characters, scenes, and other in-game elements'
                }
            ]
        },
        {
            title: 'Game Technical Artist',
            location: 'Beijing',
            type: '研发',
            descriptions: [
                {
                    title: 'Job Responsibilities',
                    content:
                        `1.In the early stages of the project, build 3D scenes and character effects that match the Demo style according to the producer's requirements (without original art design, only requirement descriptions):\n2.Scene model production and Unity scene construction, material adjustment, and atmosphere tuning;\n3.Character model and texture production and Unity material effects;\n4.Collaborate with design and programming colleagues to advance the progress of the Demo;\n5.Find suitable 3D assets or directly create 3D resources;\n6.Integrate animations into the engine (with the help of animation colleagues);\n7.Integrate special effects into the engine (with the help of special effects colleagues).`
                },
                {
                    title: 'Job Requirements',
                    content:
                        '1.Collaborate on creating artistic resources that meet project requirements for the Demo\n2.Responsible for model and material production in the Demo'
                },
                {
                    title: 'Job Description',
                    content:
                        `1.Proficient in using Unity's model, material, lighting, and other modules\n2.Proficient in Unity shader writing (Built-in and URP)\n3.Familiar with Unity's animation and special effects modules\n4.(Priority given to those with PBR production capabilities or experience in 3D rendering projects)`
                },
                {
                    title: 'Overall Requirements',
                    content:
                        '1.Skilled in a style leaning towards Western cartoons, with a high aesthetic sense\n2.Strong goal orientation, with a clear direction and quality standards for their output\n3.Proactively drive progress, able to work in an open and free environment and take responsibility for results\n4.Open-mindedness and willingness to accept new methods and optimize existing work modes\n5.Clear expression of thoughts, simple and logical communication\n6.Strong passion for games, with a personal understanding of games; enthusiasm for Habby games is a plus'
                }
            ]
        },
        {
            title: 'Game 2D Animator',
            location: 'Beijing',
            type: 'Operator',
            descriptions: [
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Create game UI animations, including interface transitions, cutscenes, interaction feedback, etc.;\n2.Participate in the early design stages of requirements, collaborating with UI to propose interaction solutions;'
                },
                {
                    title: 'Requirements',
                    content:
                        `1.Bachelor's degree or higher in Animation, with an understanding of interaction-related design languages such as visual guidance;\n2.Strong ability to express animation rhythm, with creative animation design;\n3.Proficient in using Particle System and Unity2DAnimation;\n4.Ability to use software like AE and XD to create interaction demonstration processes;\n5.Passionate about games, with good aesthetics, proactive and responsible, and possessing good teamwork and communication skills.`
                }
            ]
        },
        {
            title: 'Senior 2D Animator',
            location: 'Singapore',
            type: 'Senior 2D Animator',
            descriptions: [
                {
                    title: 'Job Responsibilities',
                    content:
                        '1.Create visually stunning UIs, 2D animations and VFX for in-game assets, cutscenes etc;\n2.Collaborate with the game development team to ensure all assets meet technical requirements and performance standards;\n3.Conceptualize and develop original animation ideas that best suit a casual mobile game;\n4.Maintain a consistent and high-quality aesthetic across all game elements.\n5.Identify and optimize animation creation processes by leveraging best practices and tools.\n6.Stay current on industry trends, technologies, and emerging techniques in animation.'
                },
                {
                    title: 'Job Requirements ',
                    content:
                        '1.5+ years of experience in western casual or mid-core games mobile game development; Familiar with Western-style game art.\n2.A complete experience in mobile game development with Unity; \n3.Capability to independently create UIs, 2D animations and VFX etc;\n4.Modesty and respect when receiving feedback from your team;\n5.Passion for games! '
                }
            ]
        },
    ]
};
