import React from 'react';
import { SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import './contact-banner.view.less';

const BannerView = () => {
    return (
        <SectionContainer
            className="section-view contact-banner-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/contact/contact_banner@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/contact/contact_banner.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h1 className="title">
                    {formatMessage('contact.banner.title')}
                </h1>
                <p className="desc">
                    {formatMessage('contact.banner.description')}
                </p>
            </div>
        </SectionContainer>
    );
};

export default BannerView;
