import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Header, Footer } from '@/components';
import { formatMessage } from '@/locales';
import './career-detail.view.less';

export const CareerDetailView = () => {
    const { index } = useParams();
    const hiringList = formatMessage('career.content4.jobs');
    const currentDetail = hiringList[index] || {};

    return (
        <>
            <Header />
            <div className="career-detail-view">
                <h2 className="title">{currentDetail.title}</h2>
                <p className="location">
                    {`Full Time / ${currentDetail.location}`}
                </p>
                {currentDetail.descriptions.map(desc => {
                    const { title, content } = desc;
                    return (
                        <Fragment key={title}>
                            <h3 className="desc-title">{title}</h3>
                            <p className="desc-content">{content}</p>
                        </Fragment>
                    );
                })}
                <p style={{ textAlign: 'center' }}>
                    <a
                        className="button"
                        href={`mailto:zhangxinzhe@habby.com?subject=${currentDetail.title}`}
                    >
                        {formatMessage('career.content4.modal.applyButtonText')}
                    </a>
                </p>
            </div>
            <Footer />
        </>
    );
};
