import React from 'react';
import { SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import Image from './img/img.png';
import './contact-content-1.view.less';

const ContactContent1View = () => {
    return (
        <SectionContainer className="contact-content-1-view">
            <div className="content">
                <div className="left">
                    <img src={Image} alt="" />
                </div>
                <div className="right">
                    <h2 className="title">
                        {formatMessage('contact.content1.title')}
                    </h2>
                    <p className="desc">
                        {formatMessage('contact.content1.desc')}
                    </p>
                </div>
            </div>
        </SectionContainer>
    );
};

export default ContactContent1View;
