/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { SectionContainer } from '@/components';
import AppStoreIcon from '../img/appstore_download_icon@2x.png';
import GooglePlayIcon from '../img/googleplay_icon@2x.png';

import './game-detail-content-3.view.less';

const GameDetailContent3View = ({ data, localId }) => {
    const { images = [], icon, name = {}, downloadUrl } = data;

    return (
        <SectionContainer className="game-detail-content-3-view">
            <div className="content">
                <ul className="display-images">
                    {images.map((image, index) => {
                        return (
                            <li key={index} className="display-image">
                                <picture>
                                    {Object.entries(image).map(
                                        ([key, value]) => {
                                            if (key === 'default') {
                                                return (
                                                    <img
                                                        key={key}
                                                        src={value}
                                                        alt=""
                                                    />
                                                );
                                            }
                                            return (
                                                <source
                                                    key={key}
                                                    srcSet={value}
                                                    media={`(max-width: ${key}px)`}
                                                />
                                            );
                                        }
                                    )}
                                </picture>
                            </li>
                        );
                    })}
                </ul>
                <div className="game-info">
                    <div className="logo-wrapper">
                        <img src={icon} alt="" />
                    </div>
                    <h3 className="game-name">{name[localId]}</h3>
                    <div className="platfom-download">
                        <div className="appstore-icon">
                            <a href={downloadUrl.ios} target="_blank">
                                <img src={AppStoreIcon} alt="" />
                            </a>
                        </div>
                        <div className="google-play-icon">
                            <a href={downloadUrl.android} target="_blank">
                                <img src={GooglePlayIcon} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </SectionContainer>
    );
};

export default GameDetailContent3View;
