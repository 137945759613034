/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';
import { Picture } from '../picture';
import './section-container.less';

export const SectionContainer = ({
    className,
    style,
    image,
    imageRender,
    children,
    ...otherProps
}) => {
    const imageRenderHandler = () => {
        if (typeof imageRender === 'function') {
            return imageRender();
        }
        if (image) {
            return <Picture src={image} />;
        }
        return null;
    };
    return (
        <div
            className={classnames('section-container', className)}
            style={style}
            {...otherProps}
        >
            {imageRenderHandler()}
            {children}
        </div>
    );
};
