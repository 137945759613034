/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import { Header, Footer } from '@/components';
import { LocalStorageService } from '@/services';
import { useParams } from 'react-router-dom';
import GameDetailBannerView from './game-detail-banner/game-detail-banner.view';

import GameDetailData from './game-detail-data';
import GameDetailContent1 from './game-detail-content-1/game-detail-content-1.view';
import GameDetailContent2 from './game-detail-content-2/game-detail-content-2.view';
import GameDetailContent3 from './game-detail-content-3/game-detail-content-3.view';

import GameSouls from './souls/index.view';

const GameDetailView = () => {
    const { id } = useParams();
    const [data] = GameDetailData.filter(item => item.id == id);

    const localId = LocalStorageService.get('localId') || 'en-US';

    const { bannerImage, game = {}, contents = [], gameDisplay = {} } =
        data || {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            {id == 'souls' ? <div className="default-view"><GameSouls></GameSouls></div>: 
            <div className="default-view">
                <GameDetailBannerView
                    imageRender={() => {
                        return (
                            <picture>
                                {Object.entries(bannerImage).map(
                                    ([key, value]) => {
                                        if (key === 'default') {
                                            return (
                                                <img
                                                    key={key}
                                                    className="background-image"
                                                    src={value}
                                                    alt="" 
                                                />
                                            );
                                        }
                                        return (
                                            <source
                                                key={key}
                                                srcSet={value}
                                                media={`(max-width: ${key}px)`}
                                            />
                                        );
                                    }
                                )}
                            </picture>
                        );
                    }}
                    localId={localId}
                />
                <GameDetailContent1 data={game} localId={localId} id={id}/>
                {contents.map((contentItem, index) => {
                    return (
                        <GameDetailContent2
                            key={index}
                            data={contentItem}
                            localId={localId}
                        />
                    );
                })}
                <GameDetailContent3 data={gameDisplay} localId={localId} />
                <Footer />
            </div>}
        </>
    );
};

export default GameDetailView;
