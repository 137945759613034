/**
 * 功能函数：处理函数
 *
 * 删除对象中
 * 值 = 'undefined'
 * 值 = undefined
 * 值 = 'null'
 * 值 = null
 *
 * @param  {[type]} params [description]
 * @return {[type]}      [description]
 */
const isObject = obj => {
    return Object.prototype.toString.call(obj) === '[object Object]';
};
const replaceUndefined = params => {
    if (params && isObject(params)) {
        const result = { ...params };
        Object.keys(result).forEach(key => {
            const value = result[key];
            if (
                value === 'undefined' ||
                value === 'null' ||
                value === null ||
                value === undefined
            ) {
                delete result[key];
            }
        });
        return result;
    }
    return {};
};

/**
 * 拦截器：无效参数处理
 *
 * 处理掉 params / data 中无效的参数
 *
 * example: http://www.xxx.com?username=undefined&password=null
 * example: http://www.xxx.com
 * @type {Array}
 */
const undefinedParamsHandlerInterceptor = [
    axiosConfig => {
        const nextConfig = {
            ...axiosConfig
        };
        if (nextConfig && nextConfig.params) {
            nextConfig.params = replaceUndefined(nextConfig.params);
        }
        if (nextConfig && nextConfig.data) {
            nextConfig.data = replaceUndefined(nextConfig.data);
        }
        return nextConfig;
    },
    error => {
        throw error;
    }
];

export default undefinedParamsHandlerInterceptor;
