const localStorage =
    typeof window === 'undefined' ? undefined : window.localStorage;

const UNIQUE_PREFIX = `HABBY_WB:`;

export const get = key => {
    let computedKey = key;
    if (!localStorage) {
        return null;
    }
    computedKey = `${UNIQUE_PREFIX}${computedKey}`;
    return JSON.parse(localStorage.getItem(computedKey));
};

export const set = (key, value) => {
    let computedKey = key;
    if (!localStorage) {
        return;
    }
    computedKey = `${UNIQUE_PREFIX}${computedKey}`;
    localStorage.setItem(computedKey, JSON.stringify(value));
};

export const remove = key => {
    let computedKey = key;
    if (!localStorage) {
        return;
    }
    computedKey = `${UNIQUE_PREFIX}${computedKey}`;
    localStorage.removeItem(computedKey);
};

export const clear = () => {
    if (!localStorage) {
        return;
    }
    localStorage.clear();
};

export default {
    get,
    set,
    remove,
    clear
};
