import React from 'react';
import { Link } from 'react-router-dom';
import { SectionContainer, Button } from '@/components';
import { formatMessage } from '@/locales';
import './about-content-3.view.less';

const AboutContent2View = () => {
    return (
        <SectionContainer
            className="about-content-3-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/about/about_content_3@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/about/about_content_3.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h2 className="title">
                    {formatMessage('about.content3.title')}
                </h2>
                <p className="desc">
                    {formatMessage('about.content3.description')}
                </p>
                <Link to="/career">
                    <Button>
                        {formatMessage('about.content3.buttonText')}
                    </Button>
                </Link>
            </div>
        </SectionContainer>
    );
};

export default AboutContent2View;
