import restfullInterceptor from './restfull.interceptor';
import undefinedParamsHandlerInterceptor from './undefined-params-handler.interceptor';
import authorizationInterceptor from './authorization.interceptor';
// import debuggerInterceptor from './debugger.interceptor';

export default [
    restfullInterceptor,
    undefinedParamsHandlerInterceptor,
    authorizationInterceptor
    // debuggerInterceptor
];
