// 同属 service 所以用相对路径引入
import { get } from '../../../local-storage.service';

/**
 * 拦截器：给 request Header 统一增加 Header
 *
 * header : {
 *     Authorization: token
 * }
 *
 * @type {Array}
 */
const authorizationInterceptor = [
    async config => {
        const token = get('token');
        const computedConfig = config;
        if (token) {
            computedConfig.headers.Authorization = token;
        }
        return computedConfig;
    },
    error => {
        throw error;
    }
];

export default authorizationInterceptor;
