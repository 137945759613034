import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';

export default () => (
    <Result
        status="403"
        title="403"
        style={{
            background: 'none'
        }}
        subTitle="对不起，你没有当前页面的权限。"
        extra={[
            <Link to="/sign-in" key="1">
                <Button type="primary">返回登录页</Button>
            </Link>,
            <Link to="/" key="2">
                <Button type="primary">返回首页</Button>
            </Link>
        ]}
    />
);
