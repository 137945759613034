/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { __DO_NOT_USE__ActionTypes } from 'redux';
import auth from './modules/auth-module.store';

// store.app.[*]
export default {
    state: {
        appName: 'Habby'
    },
    reducers: {
        APP_RESET() {
            // 清空 app 下的所有 state
            return {};
        }
    },
    actions: {
        APP_RESET({ commit, dispatch }) {
            commit('APP_RESET');
            // 从新执行 redux 的初始化逻辑
            dispatch({
                type: __DO_NOT_USE__ActionTypes.INIT
            });
        }
    },
    modules: {
        auth
    }
};
