/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { useState, Fragment } from 'react';
import { Modal } from 'antd';
import { SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import getDevice from '@/assets/js/device';
import './career-content-4.view.less';
import { useHistory } from 'react-router-dom';

const CareerContent4View = () => {
    const history = useHistory();
    const hiringList = formatMessage('career.content4.jobs');

    const device = getDevice();

    const [detailModalVisible, setDetailModalVisible] = useState(false);
    const [activeDetailIndex, setActiveDetailIndex] = useState(0);

    const currentDetail = hiringList[activeDetailIndex] || {};

    return (
        <SectionContainer
            id="career-content-4-view"
            className="career-content-4-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/career/career_content_4@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/career/career_content_4.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h1 className="title">
                    {formatMessage('career.content4.title')}
                </h1>
                <p className="sub-desc">
                    {formatMessage('career.content4.description')}
                    <span>
                        {formatMessage('career.content4.description.email')}
                    </span>
                </p>
                <ul className="hiring-list">
                    {hiringList.map((item, index) => {
                        const { title, location } = item;
                        return (
                            <li
                                className="hiring-item"
                                key={index}
                                onClick={() => {
                                    if (device.mobile) {
                                        history.push(`/career/detail/${index}`);
                                    }
                                }}
                            >
                                <span className="hiring-item-title">
                                    {title}
                                </span>
                                <span className="hiring-item-location">
                                    {location}
                                </span>
                                <span className="hiring-item-time">
                                    Full Time
                                </span>
                                <button
                                    className="hiring-button"
                                    type="button"
                                    onClick={() => {
                                        setDetailModalVisible(true);
                                        setActiveDetailIndex(index);
                                    }}
                                >
                                    {formatMessage(
                                        'career.content4.jobs.buttonText'
                                    )}
                                </button>
                                <span
                                    role="img"
                                    aria-label="caret-right"
                                    className="anticon anticon-caret-right"
                                >
                                    <svg
                                        className="arrow-right"
                                        viewBox="0 0 1024 1024"
                                        focusable="false"
                                        data-icon="caret-right"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path d="M715.8 493.5L335 165.1c-14.2-12.2-35-1.2-35 18.5v656.8c0 19.7 20.8 30.7 35 18.5l380.8-328.4c10.9-9.4 10.9-27.6 0-37z" />
                                    </svg>
                                </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <Modal
                className="career-detail-modal"
                visible={detailModalVisible}
                onCancel={() => {
                    setDetailModalVisible(false);
                }}
                footer={null}
            >
                <div className="career-detail-content">
                    <h2 className="title">{currentDetail.title}</h2>
                    <p className="location">
                        {`Full Time / ${currentDetail.location}`}
                    </p>
                    {currentDetail.descriptions.map(desc => {
                        const { title, content } = desc;
                        return (
                            <Fragment key={title}>
                                <h3 className="desc-title">{title}</h3>
                                <p className="desc-content">{content}</p>
                            </Fragment>
                        );
                    })}
                    <p style={{ textAlign: 'center' }}>
                        <a
                            className="button"
                            href={`mailto:zhangxinzhe@habby.com?subject=${currentDetail.title}`}
                        >
                            {formatMessage(
                                'career.content4.modal.applyButtonText'
                            )}
                        </a>
                    </p>
                </div>
            </Modal>
        </SectionContainer>
    );
};

export default CareerContent4View;
