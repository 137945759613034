import React from 'react';
import { SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import BackgroundImage from './img/background.png';
import IconImage1 from './img/icon1.png';
import IconImage2 from './img/icon2.png';
import './about-content-1.view.less';

const AboutContent1View = () => {
    return (
        <SectionContainer
            className="about-content-1-view"
            image={BackgroundImage}
        >
            <div className="content">
                <ul className="content-list">
                    <li className="content-list-item">
                        <img src={IconImage1} alt="" />
                        <div className="content-main">
                            <h2 className="title">
                                {formatMessage('about.content1.1.title')}
                            </h2>
                            <p className="desc">
                                {formatMessage('about.content1.1.description')}
                            </p>
                        </div>
                    </li>
                    <li className="content-list-item">
                        <img src={IconImage2} alt="" />
                        <div className="content-main">
                            <h2 className="title">
                                {formatMessage('about.content1.2.title')}
                            </h2>
                            <p className="desc">
                                {formatMessage('about.content1.2.description')}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </SectionContainer>
    );
};

export default AboutContent1View;
