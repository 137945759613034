/* eslint-disable react/prop-types */
import React from 'react';
import { SectionContainer } from '@/components';
import './game-detail-content-2.view.less';

const GameDetailContent2View = ({ data, localId }) => {
    const { image, title, infos } = data;

    return (
        <SectionContainer
            className="game-detail-content-2-view"
            imageRender={() => {
                return (
                    <picture>
                        {Object.entries(image).map(([key, value]) => {
                            if (key === 'default') {
                                return (
                                    <img
                                        key={key}
                                        className="background-image"
                                        src={value}
                                        alt=""
                                    />
                                );
                            }
                            return (
                                <source
                                    key={key}
                                    srcSet={value}
                                    media={`(max-width: ${key}px)`}
                                />
                            );
                        })}
                    </picture>
                );
            }}
        >
            <div className="content">
                <h2 className="title">{title[localId]}</h2>
                <ul className="desc-list">
                    {infos[localId]?.map(infoItem => {
                        return (
                            <li key={infoItem} className="desc-list-item">
                                {infoItem}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </SectionContainer>
    );
};

export default GameDetailContent2View;
