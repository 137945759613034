/* eslint-disable no-param-reassign */
import axios from 'axios';
import baseConfig from './config/base.config';
import { requestInterceptors, responseInterceptors } from './interceptors';

const uuid = (n = 12) => {
    const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < n; i++) {
        result += str[parseInt(Math.random() * str.length, 10)];
    }
    return result;
};

const axiosInstance = axios.create(baseConfig);

// 装载所有 request interceptor
requestInterceptors.forEach(item => {
    axiosInstance.interceptors.request.use(...item);
});

// 加载所有 response interceptor
responseInterceptors.forEach(item => {
    axiosInstance.interceptors.response.use(...item);
});

const requestTypeList = [
    'get',
    'put',
    'patch',
    'post',
    'delete',
    'options',
    'head',
    'remove'
];

const { CancelToken } = axios;

const baseService = {};

requestTypeList.forEach(type => {
    baseService[type] = (url, paramsOrData, config = {}) => {
        let abort;
        let promise;
        config = Object.assign(config, {
            cancelToken: new CancelToken(function executor(cancelFunction) {
                // An executor function receives a cancel function as a parameter
                abort = cancelFunction;
            }),
            uuid: uuid(),
            timestamp: new Date().getTime()
        });
        if (
            ['get', 'remove', 'delete', 'head', 'options'].indexOf(type) !== -1
        ) {
            if (type === 'remove') {
                type = 'delete';
            }
            config = Object.assign(config, {
                params: paramsOrData
            });
            promise = axiosInstance[type](url, config);
        } else {
            promise = axiosInstance[type](url, paramsOrData, config);
        }
        promise.abort = abort;
        return promise;
    };
});

baseService.factory = url => {
    const result = {};
    requestTypeList.forEach(type => {
        result[type] = (paramsOrData, config = {}) => {
            return baseService[type](url, paramsOrData, config);
        };
    });
    return result;
};

export default baseService;
