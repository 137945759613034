import React, { useEffect } from 'react';
import { SectionContainer, Button } from '@/components';
import { formatMessage } from '@/locales';
import { useLocation } from 'react-router-dom';
import './career-banner.view.less';

const BannerView = () => {
    const { hash } = useLocation();

    const scrollToAnchor = anchorName => {
        if (anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                // animateScroll(anchorElement, 200);
                anchorElement.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    };

    useEffect(() => {
        if (hash && hash === '#jobs') {
            setTimeout(() => {
                scrollToAnchor('career-content-4-view');
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SectionContainer
            className="career-banner-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/career/career_banner@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/career/career_banner.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h1 className="title">
                    {formatMessage('career.banner.title')}
                </h1>
                <p className="desc">
                    {formatMessage('career.banner.description')}
                </p>
                <Button
                    onClick={() => {
                        scrollToAnchor('career-content-4-view');
                    }}
                >
                    {formatMessage('career.banner.buttonText')}
                </Button>
            </div>
        </SectionContainer>
    );
};

export default BannerView;
