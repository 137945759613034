const CLIENT = 'admin';
const API_PREFIX = `/api/${CLIENT}/v1`;
const DEV_HOST = 'http://ecej-px-dev-api.yuandana.com';
const PRO_HOST = 'https://ecej-px-dev-api.yuandana.com';

let PREFIX;
if (process.env.NODE_ENV === 'development') {
    PREFIX = `${DEV_HOST}${API_PREFIX}`;
} else if (process.env.NODE_ENV === 'production') {
    PREFIX = `${PRO_HOST}${API_PREFIX}`;
}

export default {
    // 登录
    SIGNIN: `${PREFIX}/signin`,
    // 登出
    SIGNOUT: `${PREFIX}/signout`,
    // 公共接口/短信验证码
    VERIFICATION_CODE: `${PREFIX}/base/verification/code`,
    // 普通修改密码
    PASSWORD: `${PREFIX}/password`,
    // 刷新请求
    REFRESH_TOKEN: `${PREFIX}/token`,
    // 验证码修改密码
    VERIFICATION_RESET_PASSWORD: `${PREFIX}/user/reset`,

    // 课程列表
    COURSES: `${PREFIX}/courses`,
    // 课程
    COURSE: `${PREFIX}/course/:id`,
    // 线索列表
    CLUES: `${PREFIX}/clues`,
    // 线索
    CLUE: `${PREFIX}/clue/:id`,
    // 线索跟进
    CLUE_FOLLOW: `${PREFIX}/clue/follow/:id`,
    // 线索下发
    CLUE_DESIGNATE: `${PREFIX}/clue/designate`,
    // 线索的统计数量
    CLUE_STATISTICS: `${PREFIX}/clue/statistics`,
    // 线索导入
    CLUE_IMPORT: `${PREFIX}/clue/import`,
    // 线索导出
    CLUE_EXPORT: `${PREFIX}/clue/export`,
    // 下载线索导入失败的数据
    CLUE_EXPORT_FAIL: `${PREFIX}/clue/export/fail`,
    // 学籍
    ARCHIVE: `${PREFIX}/archive/:id`,
    // 学籍列表
    ARCHIVES: `${PREFIX}/archives`,
    // 交易
    BILL: `${PREFIX}/bill/:id`,
    // 交易列表
    BILLS: `${PREFIX}/bills`,
    // 退款
    DEAL_REFUND: `${PREFIX}/deal/refund`,
    // 用户
    USER: `${PREFIX}/user/:id`,
    // 用户列表
    USERS: `${PREFIX}/users`,
    // 用户
    ROLE: `${PREFIX}/role/:id`,
    // 用户列表
    ROLES: `${PREFIX}/roles`,
    // 权限
    PERMISSION: `${PREFIX}/permission/:id`,
    // 权限列表
    PERMISSIONS: `${PREFIX}/permissions`,
    // 系统字典表
    SYSTEM_CODE: `${PREFIX}/code/:type`,
    // OSS 上传文件签名
    UPLOAD_SIGNATURE: `${PREFIX}/base/oss/signature`,
    // 审批
    APPROVE: `${PREFIX}/approve/:id`,
    // 门店
    STOREFRONT: `${PREFIX}/stores`
};
