import React from 'react';
import './down-main.less';
const DownMainView = () => {

    return (
            <div className='mainWrap'>
                <div className='main'>
                    <h3>How a Robust Responsible Gaming Program Serves Players, Employees, and the Industry</h3>
                    <p className='p'>The gaming industry is forecast to surpass $200 billion in revenue in 2023<sup>1</sup>. With more people playing online than ever, how can the gaming industry, advertisers and players themselves ensure that the gaming ecosystem remains safe and entertaining?</p>
                </div>
            </div>
    );
};

export default DownMainView;
