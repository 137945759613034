/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { SectionContainer, ShadowCard } from '@/components';
import { formatMessage } from '@/locales';
import Img1 from './img/avatar01@2x.png';
import Img2 from './img/avatar02@2x.png';
import './career-content-2.view.less';

const CareerCard = ({ image, title, subTitle, content }) => {
    return (
        <ShadowCard className="career-card">
            <div className="image-wrapper">
                <img src={image} alt="" />
            </div>
            <h4 className="title">{title}</h4>
            <h5 className="sub-title">{subTitle}</h5>
            <p className="desc">{content}</p>
        </ShadowCard>
    );
};

const CareerContent2View = () => {
    const careerList = [
        {
            image: Img1,
            title: formatMessage('career.content2.1.title'),
            subTitle: formatMessage('career.content2.1.subtitle'),
            content: formatMessage('career.content2.1.description')
        },
        {
            image: Img2,
            title: formatMessage('career.content2.2.title'),
            subTitle: formatMessage('career.content2.2.subtitle'),
            content: formatMessage('career.content2.2.description')
        }
    ];
    return (
        <SectionContainer
            className="career-content-2-view"
            imageRender={() => {
                return (
                    <picture>
                        <img
                            className="background-image"
                            src={require('@/assets/img/career/career_content_2.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                {careerList.map((item, index) => {
                    const { image, title, subTitle, content } = item;

                    return (
                        <CareerCard
                            key={`${title}-${index}`}
                            image={image}
                            title={title}
                            subTitle={subTitle}
                            content={content}
                        />
                    );
                })}
            </div>
        </SectionContainer>
    );
};

export default CareerContent2View;
