function device(key) {
    const agent = navigator.userAgent.toLowerCase();
    // 获取版本号
    const getVersion = label => {
        const exp = new RegExp(`${label}/([^\\s\\_\\-]+)`);
        const nextLabel = (agent.match(exp) || [])[1];
        return nextLabel || false;
    };
    // 返回结果集
    const result = {
        os: (() => {
            // 底层操作系统
            if (/windows/.test(agent)) {
                return 'windows';
            }
            if (/linux/.test(agent)) {
                return 'linux';
            }
            if (/iphone|ipod|ipad|ios/.test(agent)) {
                return 'ios';
            }
            if (/mac/.test(agent)) {
                return 'mac';
            }
            return null;
        })(),
        ie: (() => {
            // ie版本
            return !!window.ActiveXObject || 'ActiveXObject' in window
                ? (agent.match(/msie\s(\d+)/) || [])[1] || '11' // 由于ie11并没有msie的标识
                : false;
        })(),
        weixin: getVersion('micromessenger') // 是否微信
    };

    // 任意的key
    if (key && !result[key]) {
        result[key] = getVersion(key);
    }

    // 移动设备
    result.android = /android/.test(agent);
    result.ios = result.os === 'ios';
    result.mobile = !!(result.android || result.ios);

    return result;
}

export default device;
