import React from 'react';
import { Link } from 'react-router-dom';
import { SectionContainer, Button } from '@/components';
import { formatMessage } from '@/locales';
import './game-content-2.view.less';

const GameContent2View = () => {
    return (
        <SectionContainer
            className="game-content-2-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/game/game_bottom_background@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/game/game_bottom_background.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h2 className="title">
                    {formatMessage('game.content2.title')}
                </h2>
                <ul className="desc-list">
                    {formatMessage('game.content2.list').map(item => {
                        return (
                            <li className="desc-list-item" key={item}>
                                {item}
                            </li>
                        );
                    })}
                </ul>
                <Link to="/contact">
                    <Button>{formatMessage('game.content2.buttonText')}</Button>
                </Link>
            </div>
        </SectionContainer>
    );
};

export default GameContent2View;
