import React from 'react';
import { SectionContainer } from '@/components';
import './about-banner.view.less';

const BannerView = () => {
    return (
        <SectionContainer
            className="about-banner-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/about/about_banner@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/about/about_banner.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <div className="logo-wrapper">
                    <picture>
                        <source
                            srcSet={require('@/assets/img/about/about_habby@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            src={require('@/assets/img/about/about_habby.png')}
                            alt=""
                        />
                    </picture>
                </div>
            </div>
        </SectionContainer>
    );
};

export default BannerView;
