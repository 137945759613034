export const Down = {
    click_alert: function () {
        this.loadJS();
    },

    loadFinished(tgaGameName) {
        // 创建 SDK 配置对象
        let config = {
            appId: '743689de9f5d45bea99fea4d031858b3',
            serverUrl: 'https://receiver.habby.fun/sync_js',
        };

        let thinkingdata = window.thinkingdata;
        thinkingdata.init(config);

        thinkingdata.track('site_click', {
            site_page: 'download',
            site_component: 'download_btn',
        });
    },

    loadJS() {
        var script = document.createElement('script');
        script.type = "text/javascript";
        script.src = 'https://www.thepreorders.com/h5preorders/tga.umd.min.js';
        document.getElementsByTagName('body')[0].appendChild(script);

        if (script.readyState) {  //IE
            script.onreadystatechange = function () {
                if (script.readyState == "loaded" ||
                    script.readyState == "complete") {
                    script.onreadystatechange = null;
                    Down.loadFinished();
                }
            };
        } else {  //Others
            script.onload = function () {
                Down.loadFinished();
            };
        }
    }


};