import React from 'react';
import { SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import './career-content-1.view.less';

const CareerContent1View = () => {
    return (
        <SectionContainer className="career-content-1-view">
            <div className="content">
                <div className="left">
                    <h2 className="title">
                        {formatMessage('career.content1.title')}
                    </h2>
                    <p className="desc">
                        {formatMessage('career.content1.description')}
                    </p>
                </div>
                <div className="right">
                    <div className="image-wrapper">
                        <picture>
                            <source
                                srcSet={require('@/assets/img/career/career_content_1@768.png')}
                                media="(max-width: 768px)"
                            />
                            <img
                                src={require('@/assets/img/career/career_content_1.png')}
                                alt=""
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </SectionContainer>
    );
};

export default CareerContent1View;
