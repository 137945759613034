import { Api } from '@/constants';
import httpService from './http-service';

export default {
    // 登录
    signIn: httpService.factory(Api.SIGNIN).post,
    // 登出
    signOut: httpService.factory(Api.SIGNOUT).get,
    /**
     * 验证手机验证码
     * type = 1 注册
     * type = 2 找回
     * type = 3 登陆
     */
    verification: httpService.factory(Api.VERIFICATION_CODE).get,
    // 验证码方式修改密码
    verfiyCodeResetPassword: httpService.factory(
        Api.VERIFICATION_RESET_PASSWORD
    ).put,
    // 设置密码
    updatePassword: httpService.factory(Api.PASSWORD).put,
    // 刷新token
    refreshToken: httpService.factory(Api.REFRESH_TOKEN).put
};
