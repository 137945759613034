import React from 'react';
import { Link } from 'react-router-dom';
import { AboutContainer } from '@/components';
import './about-content-2-2.view.less';
import { About } from './about';

const AboutContent22View = () => {
    return (
        <AboutContainer
            className="about-content-2-2-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/about/banner@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/about/banner.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h2 className="title">
                    RESPONSIBLE GAMING IS <br/> <span>EVERYBODY'S</span> BUSINESS
                </h2>
                <Link to="/Responsible-Gaming">
                    <button onClick={()=>{About.click_alert()}}>VIEW MORE</button>
                </Link>
            </div>
        </AboutContainer>
    );
};

export default AboutContent22View;
