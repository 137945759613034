import React from 'react';
import { Link } from 'react-router-dom';
import { Button, SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import './home-content-4.view.less';

const HomeContent4View = () => {
    return (
        <SectionContainer
            className="home-content-4-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('./img/home_page_publishing_background@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('./img/background.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h1 className="title">
                    {formatMessage({ id: 'home.content4.title' })}
                </h1>
                <p className="desc">
                    {formatMessage({ id: 'home.content4.description' })}
                </p>
                <Link to="/contact">
                    <Button>
                        {formatMessage({ id: 'home.content4.buttonText' })}
                    </Button>
                </Link>
            </div>
        </SectionContainer>
    );
};

export default HomeContent4View;
