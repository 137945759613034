/**
 * 使用黑魔法将 react-router 的 histroy 暴露出来
 */
import * as React from 'react';

export const navigationRef = React.createRef();

export function push(...args) {
    if (navigationRef.current) {
        const { history } = navigationRef.current;
        history.push(...args);
    }
}
