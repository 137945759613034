import React, { useEffect } from 'react';
import { Header, Footer } from '@/components';
import CareerBannerView from './career-banner/career-banner.view';
import CareerContent1 from './career-content-1/career-content-1.view';
import CareerContent2 from './career-content-2/career-content-2.view';
import CareerContent3 from './career-content-3/career-content-3.view';
import CareerContent4 from './career-content-4/career-content-4.view';

const CareerView = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <div className="default-view">
                <CareerBannerView />
                <CareerContent1 />
                <CareerContent2 />
                <CareerContent3 />
                <CareerContent4 />
                <Footer />
            </div>
        </>
    );
};

export default CareerView;
