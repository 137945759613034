import React from 'react';
import { SectionContainer } from '@/components';
import { formatMessage } from '@/locales';
import './game-banner.view.less';

const BannerView = () => {
    return (
        <SectionContainer
            className="game-banner-view"
            imageRender={() => {
                return (
                    <picture>
                        <source
                            srcSet={require('@/assets/img/game/game_banner@768.png')}
                            media="(max-width: 768px)"
                        />
                        <img
                            className="background-image"
                            src={require('@/assets/img/game/game_background.png')}
                            alt=""
                        />
                    </picture>
                );
            }}
        >
            <div className="content">
                <h1 className="title">{formatMessage('game.banner.title')}</h1>
                <p className="desc">
                    {formatMessage('game.banner.description')}
                </p>
            </div>
        </SectionContainer>
    );
};

export default BannerView;
