export default {
    // banner
    'home.banner.description':
        'Global publisher of hit titles like Survivor.io, Archero, Souls, Penguin Isle and many many more.',
    // souls
    'home.content00.title': 'Souls',
    'home.content00.description': `Come and take part in this ancient battle in an epic fight in good versus evil. Explore the world, win the battle and save the world.`,
    'home.content00.buttonText': 'View',
    // content 0
    'home.content0.title': 'Survivor',
    'home.content0.description': `As a human warrior with unlimited potential, you and other survivors will have to pick up your weapons and battle these evil and dangerous zombies!`,
    'home.content0.buttonText': 'View',
    // content 1
    'home.content1.title': 'Our Philosophy',
    'home.content1.description': `Gaming, as we see it, is a hobby that makes you happy. Doesn't matter if you're good or bad at it, what games you play, which platforms you prefer. As long as you enjoy your time, and it makes you forget your troubles for the day, even for a moment. Habby's all about that: dedicated to bringing fun and interaction to everyone, connecting all of us regardless of age, culture or background.`,
    'home.content1.buttonText': 'Read More',
    // content 2
    'home.content2.archero.name': 'Archero',
    'home.content2.archero.description': `Easy to play, hard to master. This unique hit is a reinvention of classic gameplay bringing a casual touch to roguelike games on mobile. One-hand controls and near-endless content - shoot and win!`,
    'home.content2.buttonText': 'View',
    // content 3
    'home.content3.title': 'Habby Culture',
    'home.content3.description': `At Habby, we're committed to bringing change to all our players. Join us on this journey to make a difference in the industry.`,
    'home.content3.buttonText': 'View Jobs',
    // content 4
    'home.content4.title': 'Game Publishing',
    'home.content4.description': `Have a game or an idea? Talk to us! We're constantly on the hunt for unique and original game ideas. We love working with studios worldwide to create the best games. Submit your game now!`,
    'home.content4.buttonText': 'Contact Us'
};
