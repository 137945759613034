/* eslint-disable react/prop-types */
import React from 'react';
import { SectionContainer } from '@/components';
import './game-detail-banner.view.less';

const BannerView = ({ image, imageRender }) => {
    return (
        <SectionContainer
            className="game-detail-banner-view"
            image={image}
            imageRender={imageRender}
        />
    );
};

export default BannerView;
