import {
    HomeView,
    CareerView,
    AboutView,
    ContactView,
    GameView,
    GameDetailView,
    CareerDetailView,
    DownView
} from '@/views';

const routes = [
    {
        path: '/',
        meta: {},
        component: HomeView
    },
    {
        path: '/game',
        meta: {},
        component: GameView
    },
    {
        path: '/game/detail/:id',
        meta: {},
        component: GameDetailView
    },
    {
        path: '/career',
        meta: {},
        component: CareerView
    },
    {
        path: '/career/detail/:index',
        component: CareerDetailView
    },
    {
        path: '/about',
        meta: {},
        component: AboutView
    },
    {
        path: '/contact',
        meta: {},
        component: ContactView
    },
    {
        path: '/Responsible-Gaming',
        meta: {},
        component: DownView
    }
    // {
    //     path: '/*',
    //     meta: {},
    //     component: NotFoundView
    // }
];

export default routes;
