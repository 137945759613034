import React from 'react';
import './down.view.less';

import DownHeaderView from './down-header/down-header';
import DownMainView from './down-main/down-main';
import DownFootView from './down-foot/down-foot';



const DownView = () => {
    return (
        <>
            <div className="down-view">
                <DownHeaderView />
                <DownMainView/>
                <DownFootView/>
                <div className='white'></div>
            </div>
        </>
    );
};

export default DownView;
