/* eslint-disable func-names */
/**
 * 拦截器：统一处理 response.data.code !== 200 的情况
 * 这里通过路由的方式重定向而不能通过 store 的方式清除登录状态
 * 在设计中 view -> store -> service -> httpService -> interceptor //-> ??store
 * 如引用 store 方式直接清除状态将会造成循环引用
 */
import { message } from 'antd';
import { useStore } from '@/store';
import { push } from '@/router/root-navigate';

const errorHandlerInterceptor = [
    function (responseData) {
        const { code, error } = responseData;

        const store = useStore();
        const { pathname, search } = window.location;

        switch (code) {
            case 200:
                return responseData;
            case 401:
                // 登录状态失效
                // 清楚登录状态相关内容
                store && store.dispatch('AUTH_SIGNOUT');
                if (pathname !== '/sign-in') {
                    // 跳转至登录页面
                    push('/sign-in', {
                        redirect: {
                            pathname,
                            search
                        }
                    });
                }
                return responseData;
            default:
                error && message.error(error);
                return responseData;
        }
    },
    function (error) {
        return error;
    }
];

export default errorHandlerInterceptor;
