import { createStore } from '@yuandana/react-redux-x';
import AppStore from './app.store';

// 创建store实例
const store = createStore({
    state: {
        // 可注入外部插入的 reducers
    },
    reducers: {},
    actions: {},
    modules: {
        // state.app.[*] 用来存储应用数据
        app: AppStore
        // state.layout.[*]
        // state.menu.[*]
        // state.router.[*]
    }
});

export const useStore = () => {
    return store;
};

export default store;
