import React, { useEffect } from 'react';
import './down-foot.less';
import { DownContainer } from '@/components';
import { Down } from '../down';

const DownFootView = () => {

    return (
            <div className='foot'>
                <DownContainer
                    className="image"
                    imageRender={() => {
                        return (
                            <picture>
                                <source
                                    srcSet={require('@/assets/img/down/phoneMockup.png')}
                                    media="(max-width: 768px)"
                                />
                                <img
                                    className="background-image"
                                    src={require('@/assets/img/down/Mockup.png')}
                                    alt=""
                                />
                            </picture>
                        );
                    }}
                    
                >
                <p className='desc'>Download this new thought leadership piece <br/> to find out how a robust responsible gaming <br/> program serves all.</p>
                <div className='btn' onClick={()=>{Down.click_alert()}}>
                    <a href={process.env.PUBLIC_URL + '/Gaming Alliance - Responsible Gaming Whitepaper.pdf'} target="_blank" download>
                        <DownContainer
                    className="btnWrap"
                    imageRender={() => {
                        return (
                                <img
                                    className="btnImg"
                                    src={require('@/assets/img/down/Button.png')}
                                    alt=""
                                />
                        );
                    }}
                    
                ></DownContainer></a> 
                </div>
                <div className='aClick'>
                1.<a href="https://newzoo.com/insights/articles/global-games-market-to-generate-175-8-billion-in-2021-despite-a-slight-decline-the-market-is-on-track-to-surpass-200-billion-in-2023/">
                        Newzoo Global Games Market Report
                    </a>
                </div>
                
                </DownContainer>
            </div>
    );
};

export default DownFootView;
