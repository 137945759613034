/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ShadowCard } from '@/components';
import './game-card.component.less';

const GameCardComponent = ({
    image,
    imageRender,
    title,
    desc,
    id,
    type,
    buttonText
}) => {
    const imageRenderHandler = () => {
        if (typeof imageRender === 'function') {
            return imageRender();
        }
        if (image) {
            return <img src={image} alt="" />;
        }
        return null;
    };
    return (
        <ShadowCard className="game-card-component">
            <div className="image-wrapper">
                {imageRenderHandler()}
                <div className="icon" />
            </div>
            <h3 className="title">{title}</h3>
            <p className="desc">{desc}</p>
            {id === 'fishdom' ? (
                <a href="https://shuizuxiang.habby.cn/" target='_target'>
                    <Button className="game-card-button">{buttonText}</Button>
                </a>
            ) : type === 'coming' ? (
                <Button className="game-card-button button-disabled">
                    {buttonText}
                </Button>
            ) : (
                <Link
                    to={location => {
                        return {
                            ...location,
                            pathname: `/game/detail/${id}`
                        };
                    }}
                >
                    <Button className="game-card-button">{buttonText}</Button>
                </Link>
            )}
        </ShadowCard>
    );
};

export default GameCardComponent;
