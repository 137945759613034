export default {
    // content 1
    'about.content1.1.title': 'Habby',
    'about.content1.1.description': `Habby is a combination of Hobby and Happy. We believe in creating happiness through hobbies by using the games that we promote and play. By playing our games, you can hope to obtain new interests and cultivate already existing ones. `,
    'about.content1.2.title': 'Copycats',
    'about.content1.2.description': `We’re totally against copying or using someone else’s ideas in our own work. We’ve all had our work copied at some point or another and we understand the pain this brings especially if you’re spent effort and time on an innovative idea. `,
    // content 2
    'about.content2.1.title': 'Founded',
    'about.content2.1.date': '2018.01',
    'about.content2.1.desc': `Habby was founded in Singapore in January 2018 by Stefan Wang.`,
    'about.content2.2.title': 'Slidey: Block Puzzle',
    'about.content2.2.date': '2018.08',
    'about.content2.2.desc': `We launched our first title - Slidey: Block Puzzle - developed by Turkish developer Mavis Games and first released in 2016. `,
    'about.content2.3.title': 'Flaming Core',
    'about.content2.3.date': '2018.10',
    'about.content2.3.desc': `Our second title - Flaming Core was launched. It was developed by 4 Chinese university students and was well received as an indie game. `,
    'about.content2.4.title': 'Archero',
    'about.content2.4.date': '2019.05',
    'about.content2.4.desc': `Archero, developed by Gorilla Games, the hit title which dominated the charts at launch.`,
    'about.content2.5.title': 'Penguin Isle',
    'about.content2.5.date': '2019.11',
    'about.content2.5.desc': `Penguin Isle with its cute and healing Penguins was developed by the Korean indie studio Fantome. `,
    // 'about.content2.6.title': 'Super Hit Baseball',
    // 'about.content2.6.date': '2020.10',
    // 'about.content2.6.desc': `Super Hit Baseball, first released by Canadian studio Hothead Games, was launched and brings a casual twist to baseball. `,
    'about.content2.6.title': 'Spin Rhythm',
    'about.content2.6.date': '2021.05',
    'about.content2.6.desc': `Spin Rhythm, a hit musical rhythm game was developed by Australian indie studio Super Spin Entertainment bringing their ‘Overwhelmingly Positive’ Steam game to mobile in May 2021.`,
    'about.content2.7.title': 'Kinja Run',
    'about.content2.7.date': '2022.07',
    'about.content2.7.desc': `Kinja Run, a hit runner game, the first of its kind combining action roguelite battle shoot-em-up! Co-developed by Hotoon Games and Gorilla Games.`,
    'about.content2.8.title': 'Survivor!.io',
    'about.content2.8.date': '2022.08',
    'about.content2.8.desc': `Survivor!.io, a roguelite survivor game where you survive against waves of zombies! Survive the fun! Developed by Gorilla Games.`,
    'about.content2.9.title': 'SSSnaker',
    'about.content2.9.date': '2023.03',
    'about.content2.9.desc': `SSSnaker, a combination of the classic Snake and a bullet-hell roguelite shooter. Developed by Yiduo Games.`,
    'about.content2.10.title': 'Souls',
    'about.content2.10.date': '2023.08',
    'about.content2.10.desc': `Souls, a unique RPG adventure  with strategy gameplay. Developed by Concrit Inc.`,
    // content 3
    'about.content3.title': 'Careers',
    'about.content3.description': `Want to join us? We believe that being simple, honest, self-driven and motivated combined with the constant pursuit of the ultimate company atmosphere can help grow a creative and robust team. Come and check out the current roles we offer now!`,
    'about.content3.buttonText': 'See Roles'
};
