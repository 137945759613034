/**
 * 授权相关状态管理模块
 */
import { AuthService, LocalStorageService } from '@/services';

export default {
    state: {
        isSignIn: false,
        initialized: false,
        token: '',
        userInfo: {},
        permissions: [],
        // 身份
        professions: [],
        isFirstSignIn: false
    },
    reducers: {
        AUTH_UPDATE_TOKEN(state, payload) {
            return { ...state, token: payload };
        },
        AUTH_UPDATE_USERINFO(state, payload) {
            return { ...state, userInfo: payload };
        },
        AUTH_SIGNIN(state, payload) {
            return { ...state, isSignIn: payload };
        },
        AUTH_UPDATE_IS_FIRST_SIGNIN(state, payload) {
            return { ...state, isFirstSignIn: payload };
        },
        AUTH_UPDATE_PERMISSIONS(state, payload = []) {
            const nextPermissions = payload.map(({ value }) => value);
            return { ...state, permissions: nextPermissions };
        },
        AUTH_UPDATE_PROFESSIONS(state, payload = []) {
            const nextProfessions = payload.map(({ value }) => value);
            return { ...state, professions: nextProfessions };
        },
        AUTH_INIT_STATUS(state, payload) {
            return { ...state, initialized: payload };
        }
    },
    actions: {
        // 清除登录数据
        AUTH_CLEAR_SIGNIN_STATUS({ commit }) {
            // 清理数据
            commit('AUTH_UPDATE_TOKEN', '');
            LocalStorageService.remove('token');

            commit('AUTH_UPDATE_USERINFO', {});
            commit('AUTH_UPDATE_PERMISSIONS', []);
            commit('AUTH_UPDATE_PROFESSIONS', []);
            LocalStorageService.remove('userInfo');

            commit('AUTH_UPDATE_IS_FIRST_SIGNIN', false);
            LocalStorageService.remove('isFirstSignIn');
            // 同步登录状态
            commit('AUTH_SIGNIN', false);
        },
        // 设置登录数据
        // 建议 auth-module 内部调用
        AUTH_SET_SIGNIN_DATA({ commit }, payload = {}) {
            const { token, userInfo, isFirstSignIn } = payload;
            const { permissions, professions } = userInfo || {};

            commit('AUTH_UPDATE_TOKEN', token);
            LocalStorageService.set('token', token);

            commit('AUTH_UPDATE_USERINFO', userInfo);
            commit('AUTH_UPDATE_PERMISSIONS', permissions);
            commit('AUTH_UPDATE_PROFESSIONS', professions);
            LocalStorageService.set('userInfo', userInfo);

            if (isFirstSignIn) {
                commit('AUTH_UPDATE_IS_FIRST_SIGNIN', isFirstSignIn);
                LocalStorageService.set('isFirstSignIn', isFirstSignIn);
            }

            if (token && !isFirstSignIn) {
                commit('AUTH_SIGNIN', true);
            }
        },
        // 退出登录
        AUTH_SIGNOUT({ state, dispatch }) {
            const { token: stateToken } = state;
            if (!stateToken) {
                dispatch('AUTH_CLEAR_SIGNIN_STATUS');
                return Promise.resolve();
            }
            return AuthService.signOut().then(responseData => {
                const { code } = responseData;
                if (code === 200) {
                    dispatch('AUTH_CLEAR_SIGNIN_STATUS');
                }
                return responseData;
            });
        },
        // 验证码方式修改密码
        AUTH_RESET_PASSWORD_BY_VERFIYCODE(_, payload = {}) {
            const { params } = payload;
            return AuthService.verfiyCodeResetPassword(params);
        },
        // 修改密码
        AUTH_UPDATE_PASSWORD({ state, commit }, payload = {}) {
            const { params } = payload;
            return AuthService.updatePassword(params).then(responseData => {
                const { code } = responseData;
                if (code === 200) {
                    const { isFirstSignIn } = state;
                    if (isFirstSignIn) {
                        commit('AUTH_UPDATE_IS_FIRST_SIGNIN', false);
                        LocalStorageService.set('isFirstSignIn', false);
                        commit('AUTH_SIGNIN', true);
                    }
                }
                return responseData;
            });
        },
        // 登录
        AUTH_SIGNIN({ dispatch }, payload = {}) {
            const { params } = payload;
            return AuthService.signIn(params).then(responseData => {
                const { code, data = {} } = responseData;
                if (code === 200) {
                    const { token, user: userInfo } = data;
                    const { isFirstSignIn } = userInfo;
                    dispatch('AUTH_SET_SIGNIN_DATA', {
                        token,
                        userInfo,
                        isFirstSignIn
                    });
                }
                return responseData;
            });
        },
        // 获取验证码
        AUTH_GET_VERIFICATION_CODE(_, payload = {}) {
            const { params } = payload;
            return AuthService.verification(params);
        },
        // 刷新token有效期
        // 如果有效则 存储 token
        async AUTH_REFRESH_TOKEN({ state, commit }) {
            const { token: stateToken } = state;
            if (!stateToken) {
                return null;
            }
            const responseData = await AuthService.refreshToken();
            const { code, data: token } = responseData;
            if (code === 200 && token) {
                commit('AUTH_UPDATE_TOKEN', token);
                LocalStorageService.set('token', token);
                return token;
            }
            commit('AUTH_UPDATE_TOKEN', '');
            LocalStorageService.remove('token');
            return false;
        },
        // 初始化登录信息
        async AUTH_INIT_LGOIN_INFO({ dispatch, commit }) {
            const token = LocalStorageService.get('token');
            const userInfo = LocalStorageService.get('userInfo');
            const isFirstSignIn = LocalStorageService.get('isFirstSignIn');
            // 判断是否为首次登录
            isFirstSignIn &&
                commit('AUTH_UPDATE_IS_FIRST_SIGNIN', isFirstSignIn);
            if (userInfo) {
                const { permissions } = userInfo;
                commit('AUTH_UPDATE_USERINFO', userInfo);
                commit('AUTH_UPDATE_PERMISSIONS', permissions);
            }
            token && commit('AUTH_UPDATE_TOKEN', token);

            // 刷新 token
            if (token) {
                const refreshToken = await dispatch('AUTH_REFRESH_TOKEN');
                if (refreshToken && !isFirstSignIn) {
                    // 此处顺序一定是先设置登录状态
                    // 先完成会造成 router 立即刷新，并且取到 isSignIn 是 false
                    commit('AUTH_SIGNIN', true);
                }
            }
            // 后完成初始化流程
            commit('AUTH_INIT_STATUS', true);
        }
    }
};
