/* eslint-disable react/prop-types */
import React from 'react';
import { Route as ReactRoute, Redirect } from 'react-router-dom';
import { NoPremissionView } from '@/views';
import store from '@/store';
import { includes } from '@/assets/js/utils';

const AuthRoute = ({ children, route, ...rest }) => {
    const { meta = {}, path } = route;
    const { requireSignInAuth, requirePermission, requirePermissions } = meta;

    const validateAuthRender = props => {
        const { location = {} } = props;
        const { pathname, search } = location;

        const state = store.getState();
        const { isSignIn, permissions } = state.app.auth;

        const routePermissions = permissions.map(value => {
            return value && value.replace('Auth://', '/');
        });

        // 如果当前路由路径不需要校验权限
        if (!pathname.includes(path)) {
            return children;
        }

        // 如果当前路由路径需要登录授权
        if (requireSignInAuth && !isSignIn) {
            return (
                <Redirect
                    to={{
                        pathname: '/sign-in',
                        state: {
                            redirect: {
                                pathname,
                                search
                            }
                        }
                    }}
                />
            );
        }

        // 如果当前路由路径需要页面权限
        // 没有当前路由权限则跳转至无权限页面
        if (
            requirePermission &&
            !routePermissions.includes('*') &&
            !routePermissions.includes(path)
        ) {
            return <NoPremissionView />;
        }

        if (
            requirePermissions &&
            !routePermissions.includes('*') &&
            !includes(requirePermissions, routePermissions)
        ) {
            return <NoPremissionView />;
        }

        return children;
    };

    return <ReactRoute {...rest} render={validateAuthRender} />;
};

// 鉴权依赖 store 需要在 store 完成时重新渲染；
export default AuthRoute;
