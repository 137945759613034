/* eslint-disable no-console */
/* eslint-disable func-names */
import axios from 'axios';

/**
 * 拦截器：统一处理 response status !== 200 的情况
 *
 * @type {Array}
 */
const errorHandlerInterceptor = [
    function (response) {
        if (axios.isCancel(response)) {
            return true;
        }
        const { status } = response;
        switch (status) {
            case 200:
                return response.data;
            default:
                console.error(`服务报错，错误码：${status} ！${response}`);
                return false;
        }
    },
    function (error) {
        const isCancel = axios.isCancel(error);
        if (isCancel) {
            return error;
        }
        console.error(`服务报错！\n ${error}`);
        return error;
    }
];

export default errorHandlerInterceptor;
